import React from "react";
import { FeatureDigitalBody, FeatureInput } from "../../types";
import FeatureBurnalong from "./FeatureBurnalong";
import FeatureListBurnalong from "./FeatureListBurnalong";
import FeatureButton from "./FeatureButton";
import { useHistory } from "react-router-dom";

const FeatureDigitalBurnAlong = ({ input }: { input: FeatureInput }) => {
  const body = input.body as FeatureDigitalBody;
  const history = useHistory();

  return (
    <FeatureListBurnalong
      headerImage={`https://${input.media?.slice(2)}`}
      backgroundColor={input.backgroundColor}
      header={body.header}
      subtext={body.subtext}
    >
      {(background) => {
        return [
          <>
            {body.features.map((f, index: number) => (
              <FeatureBurnalong
                input={{ ...f, background: background }}
                key={`list-${index}`}
              />
            ))}
            {body.buttonText && (
              <div
                style={{
                  display: `flex`,
                  marginTop: `20px`,
                  marginBottom: `20px`,
                  justifyContent: `center`,
                }}
              >
                <FeatureButton
                  text={body.buttonText}
                  onClick={() => history.push(`/enroll/eligibility`)}
                />
              </div>
            )}
          </>,
        ];
      }}
    </FeatureListBurnalong>
  );
};

export default FeatureDigitalBurnAlong;
