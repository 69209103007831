import { useContext, useEffect, useState } from "react";
import { Tile } from "./Tile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboardCheck } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";
import { NoPlanTileSVG } from "../../../resources/NoPlanTileSVG";
import { AccountContext } from "../../../context/AccountProvider";
import { useHistory } from "react-router-dom";

const NoPlanTile = () => {
  const { hasAccount, member } = useContext(AccountContext);
  const userStatus = member?.eligibilities[0]?.currentSubscriptionStatus;
  const [link, setLink] = useState("");
  const history = useHistory();

  useEffect(() => {
    if (
      userStatus === undefined ||
      userStatus === "Cancelled" ||
      typeof hasAccount === "undefined" // If the user hasAccount is undefined, the user does not have a zuora account
    ) {
      setLink("/enroll/package");
    } else {
      setLink("/account/manage");
    }
  }, []);

  const handleReenroll = () => {
    history.push(link);
  };

  return (
    <Tile className="widget__card">
      <div className="no-plan">
        <Tile.THeader>
          <div>
            <FontAwesomeIcon icon={faClipboardCheck} /> <b>MY PLAN</b>
          </div>
        </Tile.THeader>
        <Tile.TBody className="h-auto">
          <div className="d-flex flex-column justify-content-center align-items-center">
            <h3>No Current Packages</h3>
            <NoPlanTileSVG />
            <p className="p-0 text-center font-weight-light">
              Enroll in a package to take advantage of one of our premier
              fitness plans!
            </p>
          </div>
        </Tile.TBody>
        <Tile.TFooter>
          <Button
            onClick={handleReenroll}
            variant="outline-primary"
            className="btn btn-outline-primary w-75 btn-sm"
          >
            Enroll now
          </Button>
        </Tile.TFooter>
      </div>
    </Tile>
  );
};

export default NoPlanTile;
