import { useContext, useEffect, useState } from "react";
import axiosInstance from "../axios.instance";
import { Product } from "../components/wizard/forms/Package";
import {
  PROGRAM_CODE,
  USER_PAYROLL_FREQUENCY,
  UserContext,
} from "../context/UserProvider";
import { useConfig } from "../configuration/useConfig";
import { AccountContext } from "../context/AccountProvider";

const useGetPrograms = (
  enrollment?: boolean,
  isPayroll?: boolean | unknown,
  payrollFrequency?: USER_PAYROLL_FREQUENCY
) => {
  const { user } = useContext(UserContext);
  const { clientPayrollFrequency } = useContext(AccountContext);
  const [productRatePlans, setProductRatePlans] = useState<Product[]>([]);
  const [programCode, setProgramCode] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [addendum, setAddendum] = useState("per paycheck");

  const { config } = useConfig();

  //used for account
  useEffect(() => {
    if (!enrollment) {
      let programCode;
      if (config["client"] === "walmart") {
        if (user.isPayroll) {
          if (clientPayrollFrequency === USER_PAYROLL_FREQUENCY.Weekly)
            programCode = PROGRAM_CODE.WalmartPayrollWeekly;
          else programCode = PROGRAM_CODE.WalmartPayrollBiWeekly;
        } else {
          programCode = PROGRAM_CODE.PrimeLTE;
        }
      }
      if (config["client"] === "hcsc") {
        programCode = PROGRAM_CODE.PrimeSTD;
      }
      if(config["client"] === "bcbsa" || config["client"] === "bsca") {
        programCode = PROGRAM_CODE.PrimeFYW;
      }
      if (config["client"] === "mutual-of-omaha") {
        programCode = PROGRAM_CODE.PrimeLGY;
      }
      const fetchData = async () => {
        setLoading(true);
        Promise.all([
          axiosInstance.get(`/programs/${programCode}`),
          config["client"] === "hcsc"
            ? axiosInstance.get(`/programs/${PROGRAM_CODE.Studio}`)
            : config["client"] === "mutual-of-omaha" ? axiosInstance.get(`/programs/${PROGRAM_CODE.MutuallyWellEligible}`) 
            : Promise.resolve(null),
        ])
          .then(([response, additionalResponse]) => {
            if (response.data) {
              const productRatePlans = [...response.data.productRatePlans];
              if (additionalResponse && additionalResponse.data) {
                productRatePlans.push({
                  ...additionalResponse.data.productRatePlans[0],
                  name: config["client"] === "hcsc" ? "studio" : additionalResponse.data.productRatePlans[0].name,
                  tier: 0, // returns 1 for studio, but use zero for location search
                  recurringRatePlanChargeId: config["client"] === "hcsc" ?
                    additionalResponse.data.productRatePlans[0]
                      .setupRatePlanChargeId : additionalResponse.data.productRatePlans[0]
                      .recurringRatePlanChargeId,
                });
              }
              setProductRatePlans(productRatePlans);
              setProgramCode(response.data.programCode);
              sessionStorage.setItem(
                "programs",
                JSON.stringify({
                  bundles: productRatePlans,
                  plan_type:
                    config["client"] === "walmart"
                      ? user.isPayroll
                        ? clientPayrollFrequency ===
                          USER_PAYROLL_FREQUENCY.Weekly
                          ? PROGRAM_CODE.WalmartPayrollWeekly
                          : PROGRAM_CODE.WalmartPayrollBiWeekly
                        : PROGRAM_CODE.PrimeLTE
                      : config["client"] === "hcsc" ? PROGRAM_CODE.PrimeSTD 
                      : config["client"] === "bsca" || config["client"] === "bcbsa" ? PROGRAM_CODE.PrimeFYW
                      : config["client"] === "mutual-of-omaha" ? PROGRAM_CODE.PrimeLGY 
                      : "",
                })
              );

              setLoading(false);
            }
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
            setError(true);
          });
      };
      setAddendum(user.isPayroll ? "per paycheck" : "month");
      if (sessionStorage.getItem("programs")) {
        const programs = sessionStorage.getItem("programs");
        if (programs) {
          const bundles = JSON.parse(programs);
          if (bundles.plan_type === user.isPayroll) {
            setProductRatePlans(bundles.bundles);
          } else {
            fetchData();
          }
        }
      } else {
        fetchData();
      }

      return () => setLoading(false);
    }
  }, [user.isPayroll]);

  //Used for enrollments and re-enrollments
  useEffect(() => {
    if (enrollment) {
      let programCode;
      if (config["client"] === "walmart") {
        if (isPayroll) {
          if (payrollFrequency === USER_PAYROLL_FREQUENCY.Weekly)
            programCode = PROGRAM_CODE.WalmartPayrollWeekly;
          else programCode = PROGRAM_CODE.WalmartPayrollBiWeekly;
        } else {
          programCode = PROGRAM_CODE.PrimeLTE;
        }
      }
      if (config["client"] === "hcsc") {
        programCode = PROGRAM_CODE.PrimeSTD;
      }
      if(config["client"] === "bcbsa" || config["client"] === "bsca") {
        programCode = PROGRAM_CODE.PrimeFYW;
      }
      if (config["client"] === "mutual-of-omaha") {
        programCode = PROGRAM_CODE.PrimeLGY;
      }
      const fetchData = async () => {
        setLoading(true);
        Promise.all([
          axiosInstance.get(`/programs/${programCode}`),
          config["client"] === "hcsc"
            ? axiosInstance.get(`/programs/${PROGRAM_CODE.Studio}`)
            : config["client"] === "mutual-of-omaha" ? axiosInstance.get(`/programs/${PROGRAM_CODE.MutuallyWellEligible}`) 
            : Promise.resolve(null),
        ])
          .then(([response, additionalResponse]) => {
            if (response.data) {
              const productRatePlans = [...response.data.productRatePlans];
              if (additionalResponse && additionalResponse.data) {
                productRatePlans.push({
                  ...additionalResponse.data.productRatePlans[0],
                  name: config["client"] === "hcsc" ? "studio" : additionalResponse.data.productRatePlans[0].name,
                  tier: 0, // returns 1 for studio, but use zero for location search
                  recurringRatePlanChargeId: config["client"] === "hcsc" ?
                  additionalResponse.data.productRatePlans[0]
                    .setupRatePlanChargeId : additionalResponse.data.productRatePlans[0]
                    .recurringRatePlanChargeId,
                });
              }
              setProductRatePlans(productRatePlans);
              sessionStorage.setItem(
                "programs",
                JSON.stringify({
                  bundles: productRatePlans,
                  plan_type:
                    config["client"] === "walmart"
                      ? isPayroll
                        ? payrollFrequency === USER_PAYROLL_FREQUENCY.Weekly
                          ? PROGRAM_CODE.WalmartPayrollWeekly
                          : PROGRAM_CODE.WalmartPayrollBiWeekly
                        : PROGRAM_CODE.PrimeLTE
                        : config["client"] === "hcsc" ? PROGRAM_CODE.PrimeSTD 
                        : config["client"] === "bsca" || config["client"] === "bcbsa" ? PROGRAM_CODE.PrimeFYW
                        : config["client"] === "mutual-of-omaha" ? PROGRAM_CODE.PrimeLGY 
                        : "",
                })
              );
              setLoading(false);
            }
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
            setError(true);
          });
      };
      setAddendum(isPayroll ? "per paycheck" : "month");
      if (sessionStorage.getItem("programs")) {
        const programs = sessionStorage.getItem("programs");
        if (programs) {
          const bundles = JSON.parse(programs);
          if (bundles.plan_type === isPayroll) {
            setProductRatePlans(bundles.bundles);
          } else {
            fetchData();
          }
        }
      } else {
        fetchData();
      }

      return () => setLoading(false);
    }
  }, [isPayroll]);

  return { error, loading, productRatePlans, addendum, programCode };
};

export default useGetPrograms;
