import React, { useContext, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import {
  PROGRAM_CODE,
  USER_PAYROLL_FREQUENCY,
  UserContext,
} from "../../context/UserProvider";
import { useHistory } from "react-router-dom";
import axiosInstance from "../../axios.instance";
import { convertToCurrency } from "../../utility/util";
import { useConfig } from "../../configuration/useConfig";
import { IPackageSelectFields } from "../../@types/generated/contentful";
import { AccountContext } from "../../context/AccountProvider";

export type Product = {
  id: string;
  billingPeriod: string;
  credits: number;
  description: string;
  isBestValue: boolean;
  name: string;
  recurringPrice: number;
  recurringRatePlanChargeId: string;
  setupFee: number;
  setupRatePlanChargeId: string;
  tier: number;
};

const PackageSelect = ({
  header,
  subHeader,
  description,
  digitalHeader,
  digitalSubHeader,
  digitalDescription,
}: IPackageSelectFields) => {
  const history = useHistory();
  const { payrollFrequency } = useContext(AccountContext);
  const { user } = useContext(UserContext);
  const { config } = useConfig();

  if (!user.isSSO) {
    return null;
  }

  const [, setLoadingActivityLog] = useState(false);
  const [programCode, setProgramCode] = useState("");
  const [primeProductRatePlans, setPrimeProductRatePlans] = useState<Product[]>(
    []
  );

  useEffect(() => {
    if (config["client"] === "walmart") {
      if (user.isPayroll) {
        if (payrollFrequency === USER_PAYROLL_FREQUENCY.Weekly)
          setProgramCode(PROGRAM_CODE.WalmartPayrollWeekly);
        else setProgramCode(PROGRAM_CODE.WalmartPayrollBiWeekly);
      } else {
        setProgramCode(PROGRAM_CODE.PrimeLTE);
      }
    }
    if (config["client"] === "hcsc") {
      setProgramCode(PROGRAM_CODE.PrimeSTD);
    }
    if (config["client"] === "bsca" || config["client"] === "bcbsa") {
      setProgramCode(PROGRAM_CODE.PrimeFYW);
    }
    if (config["client"] === "mutual-of-omaha") {
      setProgramCode(PROGRAM_CODE.PrimeLGY);
    }
  }, [user.isPayroll]);

  useEffect(() => {
    const getDetails = async () => {
      setLoadingActivityLog(true);
      if (programCode) {
        axiosInstance
          .get(`/programs/${programCode}`)
          .then((response) => {
            if (response.data) {
              if (programCode === PROGRAM_CODE.PrimeLGY) {
                axiosInstance
                  .get(`/programs/${PROGRAM_CODE.MutuallyWellEligible}`)
                  .then((responseDigital) => {
                    if (responseDigital.data) {
                      const gymPackages = response.data
                        .productRatePlans as Product[];
                      gymPackages.push(
                        responseDigital.data.productRatePlans[0]
                      );
                      setPrimeProductRatePlans(gymPackages);
                      setLoadingActivityLog(false);
                    }
                  });
              } else {
                setPrimeProductRatePlans(response.data.productRatePlans);
                setLoadingActivityLog(false);
              }
            }
          })
          .catch((error) => {
            console.log(error);
            setLoadingActivityLog(false);
          });
      }
    };
    getDetails();
  }, [programCode]);

  return (
    <div className="feature-package-select">
      <section>
        <h1>{header}</h1>
        <p className="h3">{subHeader}</p>
        <p className="h4" style={{ color: "#000" }}>
          <i>{description}</i>
        </p>
      </section>
      <section className="feature-package-select__cards d-flex flex-wrap">
        {primeProductRatePlans.map((card, index) => {
          if (card.name !== "Fitness Pass Digital") {
            return (
              <div
                className={`feature-package-select__card ${
                  card.isBestValue ? "best-value" : ""
                }`}
                key={`feature-card-${index}`}
              >
                {card.isBestValue && (
                  <div className="best-value-tag">Most Popular!</div>
                )}
                <div className="feature-package-select__tier-tag">
                  {card.name}
                </div>
                <div className="feature-package-select__price-container">
                  <div className="feature-package-select__price">
                    {convertToCurrency(card.recurringPrice) ?? 0}
                  </div>
                  <div className="digital-content-only-container__duration">
                    {user.isPayroll ? "per paycheck" : "/ month"}
                  </div>
                  <Button
                    variant="outline-primary"
                    className="feature-content__button-select"
                    style={{
                      whiteSpace: "nowrap",
                      margin: ` 1rem auto 0`,
                      background: `white`,
                      borderRadius: "5px",
                      width: `168px`,
                    }}
                    onClick={() =>
                      history.push(`/enroll/package?tier=${card.tier}`)
                    }
                  >
                    <span>Select</span>
                  </Button>
                </div>
                <div
                  style={{
                    width: `100%`,
                    background: `#ffffff`,
                    borderBottomLeftRadius: "5px",
                    borderBottomRightRadius: "5px",
                    paddingTop: "10px",
                  }}
                >
                  <div className="feature-package-select__gym-count-container">
                    <div className="feature-package-select__location-mark-icon">
                      <svg viewBox="0 0 384 512" width="20">
                        <path
                          fill="#ffc220"
                          d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"
                        />
                      </svg>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div
                        className="feature-package-select__gym-count"
                        dangerouslySetInnerHTML={{
                          __html: card.description,
                        }}
                      ></div>
                    </div>
                  </div>
                  <hr style={{ marginLeft: `10%`, marginRight: `10%` }} />
                  <div className="feature-package-select__studio-class-reward">
                    <svg viewBox="0 0 15 15" width="22">
                      <path
                        d="M4.069 8.758L.176 4.865a.6.6 0 0 1 0-.847l.847-.847a.6.6 0 0 1 .847 0l2.623 2.623L10.11.177a.6.6 0 0 1 .847 0l.847.847a.6.6 0 0 1 0 .847L4.916 8.758a.6.6 0 0 1-.847 0z"
                        fill="#ffc220"
                      />
                    </svg>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        paddingTop: `3%`,
                      }}
                    >
                      <span>Digital Content</span>
                      <p>Videos & Live Streams</p>
                    </div>
                  </div>
                </div>
              </div>
            );
          }
        })}
      </section>
      <section className="digital-only-text">
        <h1>{digitalHeader}</h1>
        <p className="h3">{digitalSubHeader}</p>
        <p className="h4" style={{ color: "#000" }}>
          <i>{digitalDescription}</i>
        </p>
      </section>
      {primeProductRatePlans.map((item) => {
        if (item.name === "Fitness Pass Digital") {
          return (
            <section>
              <div
                className={`digital-content-only-container ${
                  !user.isPayroll
                    ? "digital-content-only-container__non-payroll"
                    : ""
                }`}
              >
                <div className="digital-content-only-container__tag">
                  {item.name}
                </div>
                <div
                  className="digital-content-only-container__content-row"
                  style={{ marginLeft: "-10px" }}
                >
                  <div
                    className="digital-content-only-container__price-container"
                    style={{ marginTop: "5px" }}
                  >
                    {user.isPayroll ? (
                      <>
                        <div className="digital-content-only-container__price">
                          ${item.recurringPrice ?? 0}
                        </div>
                        <div className="digital-content-only-container__duration">
                          per paycheck
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="digital-content-only-container__price">
                          ${item.recurringPrice ?? 0}
                        </div>
                        <div className="digital-content-only-container__duration">
                          / month
                        </div>
                      </>
                    )}
                  </div>
                  <div className="digital-content-only-container__content-column">
                    <div className="digital-content-only-container__description">
                      <div className="digital-content-only-container__description-top-line">
                        Add hundreds of on-demand videos and live streams to
                        your plan.
                      </div>
                      <div className="digital-content-only-container__description-bottom-line">
                        Content includes workouts, wellness videos, nutrition
                        tutorials, and more!
                      </div>
                    </div>
                  </div>
                  <Button
                    variant="outline-primary"
                    className="feature-content__button-select"
                    style={{
                      whiteSpace: "nowrap",
                      background: `white`,
                      borderRadius: "5px",
                      width: `16px`,
                    }}
                    onClick={() => history.push(`/enroll/package?tier=digital`)}
                  >
                    <span>Select</span>
                  </Button>
                </div>
              </div>
            </section>
          );
        }
      })}
    </div>
  );
};

export default PackageSelect;
