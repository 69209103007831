import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { FormikWizardState, IEligibilityFormProfileMW } from "../types";
import React, { useContext, useEffect, useState } from "react";
import {
  ELIGIBILITY_MESSAGE,
  USER_PAYROLL_STATUS,
  USER_STATUS,
  UserContext,
} from "../../../context/UserProvider";
import { getIn, useFormikContext } from "formik";

import FooterCard from "../components/FooterCard";
import Header from "../components/Header";
import StatusModal from "../components/StatusModal";
import { ImpersonationContext } from "../../../context/ImpersonationProvider";
import useRouteQuery from "../../../hooks/useRouteQuery";
import axiosInstance from "../../../axios.instance";
import { useConfig } from "../../../configuration/useConfig";

const Eligibility = ({ next }: { next?: () => void }) => {
  const {
    values,
    errors,
    touched,
    setFieldValue,
    handleChange,
    handleBlur,
    setFieldTouched,
  } = useFormikContext<FormikWizardState<IEligibilityFormProfileMW>>();

  const { setUser } = useContext(UserContext);
  const [status, setStatus] = useState("");
  const [message, setMessage] = useState("");
  const [clientCode, setClientCode] = useState("");
  const [cardOneRef] = useState<HTMLDivElement | null>(null);
  const [, setRefs] = useState<(HTMLDivElement | null)[]>([]);
  const [progress, setProgress] = useState(0);
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);
  const [impersonateLockForm, setImpersonateLockForm] =
    useState<boolean>(false);
  const { isImpersonated } = useContext(ImpersonationContext);
  const { clientConfig } = useConfig();

  const query = useRouteQuery();

  useEffect(() => {
    setRefs([cardOneRef]);
  }, [cardOneRef]);

  //set impersonate value from browser parameter
  useEffect(() => {
    setImpersonateLockForm(isImpersonated && window.location.search.length > 0);
    if (isImpersonated && query.get("firstName")) {
      const dob = new Date(query.get("dob") || 0);
      const year = dob.getFullYear();
      const month = ("0" + (dob.getMonth() + 1)).slice(-2).toString();
      const day = ("0" + dob.getDate()).slice(-2).toString();
      setFieldValue("eligibility.firstName", query.get("firstName"));
      setFieldValue("eligibility.lastName", query.get("lastName"));
      setFieldValue("eligibility.year", year);
      setFieldValue("eligibility.day", day);
      setFieldValue("eligibility.month", month);
      setFieldValue(
        "eligibility.associateIdentificationNumber",
        query.get("subscriberId")
      );
      setFieldValue("eligibility.email", query.get("email"));
      setFieldValue("eligibility.subscriberId", query.get("subscriberId"));
      setFieldValue("eligibility.zip", query.get("zip"));

      setFieldTouched("eligibility.name", true);
    }
  }, [isImpersonated]);

  useEffect(() => {
    if (values.eligibility?.completed && next) {
      next();
    }
  }, []);

  useEffect(() => {
    if (status && intervalId) {
      if (progress >= 100) {
        setProgress(100);
        clearInterval(intervalId);
        setIntervalId(null);
      }
    }
    () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [progress, status]);

  const getAge = (dateString: string) => {
    const today = new Date(),
      birthDate = new Date(dateString);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  const isOverMinBuyerAge = () => {
    if (values.eligibility && values.eligibility.year) {
      const age = getAge(
        `${values.eligibility?.month}/${values.eligibility?.day}/${values.eligibility?.year}`
      );
      if (!isNaN(age) && age >= clientConfig.minBuyerMemberAge!) return true;
      return false;
    }
  };

  const handleCheckEligibility = async () => {
    setProgress(0);
    setIntervalId(setInterval(() => setProgress((p) => p + 5), 700));
    const vals = values.eligibility! as IEligibilityFormProfileMW;
    vals.firstName = vals.firstName.trim();
    vals.lastName = vals.lastName.trim();
    vals.year = vals.year.trim();
    vals.month = vals.month.trim();
    vals.day = vals.day.trim();
    vals.email = vals.email.trim();
    vals.zip = vals.zip.trim();
    if (typeof vals.subscriberId === "string") {
      vals.subscriberId = vals.subscriberId.trim();
    }
    if (typeof vals.groupNumber === "string") {
      vals.groupNumber = vals.groupNumber.trim();
    }
    if (typeof vals.corpCode === "string") {
      vals.corpCode = vals.corpCode.trim();
    }
    const eligibilityEndpoint = "/eligibility";
    setStatus("loading");
    setMessage("We're confirming your information");

    axiosInstance
      .post(eligibilityEndpoint, {
        firstName: vals.firstName,
        lastName: vals.lastName,
        dateOfBirth: `${vals.year}-${vals.month}-${vals.day}`,
        email: vals.email,
        zip: vals.zip,
        subscriberId: vals.subscriberId,
        groupNumber: vals.groupNumber,
        corporationCode: vals.corpCode,
        isDependent: false,
        createLogin: true,
        clientSpecificData: {
          clientCode: "mutual-of-omaha",
        },
      })
      .then((response) => {
        switch (response.data?.message) {
          case ELIGIBILITY_MESSAGE.Eligible:
            setFieldValue("eligibility.completed", true);
            setFieldValue("eligibility.consumerId", response.data.consumerId);
            setFieldValue(
              "eligibility.clientMemberId",
              response.data.clientMemberId
            );
            setFieldValue(
              "eligibility.clientCode",
              response.data.clientSpecificData.clientCode
            );
            setFieldValue("eligibility.isPayroll", false);
            setClientCode(response.data.clientSpecificData.clientCode);

            setFieldValue(
              "eligibility.groupId",
              response.data.clientSpecificData?.groupId
            );
            setFieldValue(
              "eligibility.misc3",
              response.data.clientSpecificData?.misc3
            );
            setFieldValue(
              "eligibility.misc6",
              response.data.clientSpecificData?.misc6
            );
            setFieldValue(
              "eligibility.misc9",
              response.data.clientSpecificData?.misc9
            );

            setUser({
              status: USER_STATUS.ELIGIBLE,
              isPayroll:
                response.data.payrollStatus === USER_PAYROLL_STATUS.Payroll,
            });
            setStatus("CONFIRMED");
            break;
          case ELIGIBILITY_MESSAGE.NotEligible:
            setStatus("DENIED");
            setMessage(
              "The information you provided could not be confirmed as eligible. Please review your information, and try again."
            );
            break;
          case ELIGIBILITY_MESSAGE.EmailUnavailable:
            setStatus("NOEMAIL");
            setMessage(
              "The email address you entered is already in use. Please use a different email address for your account creation."
            );
            break;
          case ELIGIBILITY_MESSAGE.LoginCreated:
            setStatus(ELIGIBILITY_MESSAGE.LoginCreated);
            // setMessage(
            //   "Your Account has been created, please check your email to complete account creation."
            // );
            break;
          case ELIGIBILITY_MESSAGE.ExistingLoginFound:
            setStatus("EXISTS");
            setMessage(
              "Our Records indicate that you already have an account with us. Please login or reset your password to access your account."
            );
            break;
        }
      })
      .catch((error) => {
        console.log(error.toJSON());
        setMessage(
          "The information you provided could not be confirmed as eligible. Please review your information, and try again."
        );
        setStatus("DENIED");
      });
  };

  const handleHandoff = () => {
    if (next) {
      next();
    }
  };

  const scrollTo = (elementName) => {
    const el = document.getElementsByName(elementName);
    el[0].scrollIntoView();
  };

  const handlePrivacyPolicyKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      setFieldValue(
        "eligibility.privacyPolicy",
        !values.eligibility!.privacyPolicy
      );
    }
  };

  const isErrorFound = () => {
    const eligibility = values.eligibility as {
      month?: string;
      day?: string;
      year?: string;
      privacyPolicy?: boolean;
    };

    return (
      Boolean(errors.eligibility !== undefined) ||
      (eligibility.month &&
        typeof eligibility.month === "string" &&
        eligibility.month.length < 2) ||
      (eligibility.day &&
        typeof eligibility.day === "string" &&
        eligibility.day.length < 2) ||
      (eligibility.year &&
        typeof eligibility.year === "string" &&
        eligibility.year.length < 4) ||
      !isOverMinBuyerAge() ||
      eligibility.privacyPolicy !== true
    );
  };

  return (
    <div className="eligibility-form-container">
      <StatusModal
        show={status !== ""}
        status={status}
        message={message}
        onCancel={() => setStatus("")}
        onNext={handleHandoff}
        progress={progress}
        clientCode={clientCode}
      />
      <Header
        title={`Check Your Eligibility`}
        subtitle={`Please enter the following information exactly as it is presented on your Medicare Supplement Card.`}
      />
      <Card className="eligibility-form" style={{ marginTop: 20 }}>
        <Card.Body style={{ padding: "24px" }}>
          <Row className="eligibility-form__row">
            <Form.Group
              as={Col}
              controlId="firstName"
              xs={12}
              md={4}
              lg={3}
              className="mb-3"
            >
              <Form.Label className="form-control-sm col-form-label p-0 pb-1 eligibility-form__label">
                First Name<span className="required-field-marker">*</span>
              </Form.Label>
              <Form.Control
                className="eligibility-placeholder"
                type="text"
                required
                maxLength={35}
                name="eligibility.firstName"
                value={values.eligibility!.firstName as string}
                placeholder="Enter first name"
                disabled={impersonateLockForm}
                isInvalid={
                  getIn(touched, "eligibility.firstName") &&
                  getIn(errors, "eligibility.firstName")
                }
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <Form.Control.Feedback type="invalid">
                {getIn(errors, "eligibility.firstName")}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} controlId="lastName" xs={12} md={4} lg={3}>
              <Form.Label className="form-control-sm col-form-label p-0 pb-1 eligibility-form__label">
                Last Name<span className="required-field-marker">*</span>
              </Form.Label>
              <Form.Control
                className="eligibility-placeholder"
                type="text"
                required
                maxLength={60}
                name="eligibility.lastName"
                value={values.eligibility!.lastName as string}
                placeholder="Enter last name"
                disabled={impersonateLockForm}
                isInvalid={
                  getIn(touched, "eligibility.lastName") &&
                  getIn(errors, "eligibility.lastName")
                }
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <Form.Control.Feedback type="invalid">
                {getIn(errors, "eligibility.lastName")}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Form.Label
            className="form-control-sm col-form-label p-0 eligibility-form__label"
            style={{ marginBottom: "-10px" }}
          >
            Date of Birth<span className="required-field-marker">*</span>
          </Form.Label>
          <Row className="eligibility-form__row">
            <Form.Group
              as={Col}
              controlId="month"
              xs={6}
              sm={3}
              md={2}
              xl={1}
              className="mb-3"
            >
              <Form.Label
                className="form-control-sm col-form-label p-0 eligibility-form__sub-label"
                style={{ marginBottom: "-10px" }}
              >
                Month
              </Form.Label>
              <Form.Control
                className="eligibility-placeholder"
                type="text"
                name="eligibility.month"
                placeholder="MM"
                value={values.eligibility!.month}
                disabled={impersonateLockForm}
                isInvalid={
                  (getIn(touched, "eligibility.month") &&
                    getIn(errors, "eligibility.month")) ||
                  (getIn(touched, "eligibility.month") &&
                    (values.eligibility?.month as string).length !== 2) ||
                  (getIn(touched, "eligibility.month") &&
                    isNaN(Number(values.eligibility?.month)))
                }
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="day" xs={6} sm={3} md={1} xl={1}>
              <Form.Label
                className="form-control-sm col-form-label p-0 eligibility-form__sub-label"
                style={{ marginBottom: "-10px" }}
              >
                Day
              </Form.Label>
              <Form.Control
                className="eligibility-placeholder"
                type="text"
                name="eligibility.day"
                placeholder="DD"
                value={values.eligibility!.day}
                disabled={impersonateLockForm}
                isInvalid={
                  (getIn(touched, "eligibility.day") &&
                    getIn(errors, "eligibility.day")) ||
                  (getIn(touched, "eligibility.day") &&
                    (values.eligibility?.day as string).length !== 2) ||
                  (getIn(touched, "eligibility.day") &&
                    isNaN(Number(values.eligibility?.day)))
                }
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="year" xs={6} sm={3} md={2} xl={1}>
              <Form.Label
                className="form-control-sm col-form-label p-0 pb-0 eligibility-form__sub-label"
                style={{ marginBottom: "-10px" }}
              >
                Year
              </Form.Label>
              <Form.Control
                className="eligibility-placeholder"
                type="text"
                name="eligibility.year"
                value={values.eligibility!.year}
                disabled={impersonateLockForm}
                isInvalid={
                  (getIn(touched, "eligibility.year") &&
                    getIn(errors, "eligibility.year")) ||
                  (getIn(touched, "eligibility.year") &&
                    (values.eligibility?.year as string).length !== 4) ||
                  (getIn(touched, "eligibility.year") &&
                    isNaN(Number(values.eligibility?.year))) ||
                  (getIn(touched, "eligibility.year") && !isOverMinBuyerAge())
                }
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="YYYY"
              />
            </Form.Group>
          </Row>
          {getIn(touched, "eligibility.year") && !isOverMinBuyerAge() && (
            <div className="invalid-feedback-dob">
              Must be{" "}
              {clientConfig?.minBuyerMemberAge
                ? clientConfig.minBuyerMemberAge.toString()
                : "18"}{" "}
              years or older
            </div>
          )}
          <Row className="eligibility-form__row">
            <Form.Group as={Col} xs={9} sm={5} md={3} className="mb-3">
              <Form.Label className="form-control-sm col-form-label p-0 pb-1 eligibility-form__label">
                Email Address
                <span className="required-field-marker">*</span>
              </Form.Label>
              <Form.Control
                className="eligibility-placeholder"
                type="text"
                name="eligibility.email"
                value={values.eligibility!.email as string}
                placeholder="Enter email address"
                isInvalid={
                  getIn(touched, "eligibility.email") &&
                  getIn(errors, "eligibility.email")
                }
                onChange={handleChange}
                onBlur={handleBlur}
                onFocus={() => scrollTo("eligibility.day")}
              />
              <Form.Control.Feedback type="invalid">
                {getIn(errors, "eligibility.email")}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row className="eligibility-form__row">
            <Form.Group as={Col} xs={9} sm={5} md={3} className="mb-3">
              <Form.Label className="form-control-sm col-form-label p-0 pb-1 eligibility-form__label">
                Zip Code<span className="required-field-marker">*</span>
              </Form.Label>
              <Form.Control
                className="eligibility-placeholder"
                type="text"
                name="eligibility.zip"
                value={values.eligibility!.zip}
                placeholder="Enter zip code"
                isInvalid={
                  getIn(touched, "eligibility.zip") &&
                  getIn(errors, "eligibility.zip")
                }
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <Form.Control.Feedback type="invalid">
                {getIn(errors, "eligibility.zip")}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row className="eligibility-form__row">
            <Form.Group
              className="mb-3"
              controlId="formBasicCheckboxPrivacyPolicy"
            >
              <Row className="eligibility-form__row">
                <Col
                  xs={1}
                  style={{ width: "20px", paddingTop: "4px", marginRight: "0" }}
                >
                  <Form.Check
                    id="privacy"
                    value="true"
                    isInvalid={
                      getIn(touched, "eligibility.privacyPolicy") &&
                      getIn(errors, "eligibility.privacyPolicy")
                    }
                    name="eligibility.privacyPolicy"
                    checked={values.eligibility!.privacyPolicy === true}
                    inline
                    style={{ paddingLeft: 0 }}
                    onChange={handleChange}
                    onKeyDown={handlePrivacyPolicyKeyDown}
                  />
                </Col>
                <Col xs={9} sm={8} style={{ padding: 0, margin: 0 }}>
                  <span style={{ fontSize: "12px", color: "#696969" }}>
                    <span className="required-field-marker">*</span>By providing
                    your email address and/or any other personal information, as
                    defined under applicable law, you acknowledge that you are
                    agreeing to our use of your information as provided in our{" "}
                    <a
                      href="/terms"
                      target="_blank"
                      rel="noreferrer"
                      className="a-link"
                    >
                      Terms of Use
                    </a>{" "}
                    and{" "}
                    <a
                      href="/privacy-policy"
                      target="_blank"
                      rel="noreferrer"
                      className="a-link"
                    >
                      Privacy Policy
                    </a>
                  </span>
                </Col>
              </Row>
              <Row className="eligibility-form__row">
                <Form.Control.Feedback type="invalid">
                  {getIn(errors, "eligibility.privacyPolicy")}
                </Form.Control.Feedback>
              </Row>
            </Form.Group>
          </Row>
        </Card.Body>
      </Card>
      <FooterCard>
        <Container>
          <Row>
            <Col sm={{ span: 3, offset: 4 }} lg={{ span: 3, offset: 5 }}></Col>
            <Col sm={5} lg={3}>
              <>
                <Button
                  variant="primary"
                  className="nav-btn-enroll font-weight-bold"
                  style={{
                    whiteSpace: "nowrap",
                    width: "100%",
                    color: "#FFFFFF",
                  }}
                  onClick={handleCheckEligibility}
                  disabled={
                    Object.keys(touched).length === 0 ? true : isErrorFound()
                  }
                >
                  Check My Eligibility
                </Button>
              </>
            </Col>
          </Row>
        </Container>
      </FooterCard>
    </div>
  );
};

export default Eligibility;
