import React, { useEffect, useState } from "react";
import { AccountSummaryMember } from "../../utility/member-portal-api-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col, Form } from "react-bootstrap";
import skuMap from "../../utility/skuMap";
import BundlesDropDownDependent from "./BundlesDropDownDependent";
import BundlesDropDownUnderageDependent from "./BundlesDropDownUnderageDependent";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { useConfig } from "../../configuration/useConfig";
import useAgeValidation from "../../hooks/useAgeValidation";

const DependentCardBody = ({
  dependent,
  highestSelectableTier,
  handleDependentPlanChange,
  setSelectedMember,
  triggerPreviewPrimary,
  getProductTierFromRatePlanChargeId,
  shouldRevertToDefault,
  setDependentToPrimarySelection = false,
  parentRatePlanChargeId,
  disable,
}: {
  dependent: AccountSummaryMember;
  highestSelectableTier: string | undefined;
  handleDependentPlanChange: (dependentNewRatePlan, dependent) => void;
  setSelectedMember: (dependent: AccountSummaryMember) => void;
  triggerPreviewPrimary: () => void;
  getProductTierFromRatePlanChargeId: (
    ratePlanChargeId: string
  ) => string | undefined;
  planId?: string;
  membersPage?: boolean;
  shouldRevertToDefault?: boolean;
  setDependentToPrimarySelection?: boolean;
  parentRatePlanChargeId?: string;
  disable: boolean;
}) => {
  const faEnvelopeIcon = faEnvelope as IconProp;
  const { clientConfig } = useConfig();
  const [isChange, setIsChange] = useState<boolean>(false);
  const [dropDownLoaded, setDropDownLoaded] = useState<boolean>(false);

  const futurePlanChargeId = dependent?.eligibilities
    ? dependent?.eligibilities[0].futureSubscription?.ratePlanChargeId
    : "";
  const currentRatePlanChargeId = dependent?.eligibilities
    ? dependent?.eligibilities[0].currentSubscription?.ratePlanChargeId
    : "";

  const [selectedPlan, setSelectedPlan] = useState<string>(
    currentRatePlanChargeId ?? ""
  );

  const isUnderage = useAgeValidation({
    dateOfBirth: dependent.dateOfBirth,
    ageLimit: clientConfig.minBuyerMemberAge!,
  });

  useEffect(() => {
    setSelectedPlan(
      futurePlanChargeId || selectedPlan || currentRatePlanChargeId || ""
    );
  }, [dropDownLoaded]);

  useEffect(() => {
    //this is causing bug when dependent has future plan, had to add futurePlanChargeId === undefined to fix it from triggering preview call on render
    if (
      futurePlanChargeId === undefined &&
      selectedPlan !== currentRatePlanChargeId
    ) {
      handleDependentPlanChange(selectedPlan, dependent);
      setIsChange(true);
    } else if (
      futurePlanChargeId === undefined &&
      isChange &&
      selectedPlan === currentRatePlanChargeId
    ) {
      handleDependentPlanChange(selectedPlan, dependent);
    }
  }, [selectedPlan]);

  useEffect(() => {
    if (setDependentToPrimarySelection && !futurePlanChargeId) {
      const currentSelectedPlanTier =
        getProductTierFromRatePlanChargeId(selectedPlan);
      if (
        highestSelectableTier &&
        parseInt(highestSelectableTier) >= 4 &&
        parseInt(currentSelectedPlanTier ?? "3") > 3
      ) {
        setSelectedPlan(parentRatePlanChargeId ?? "");
      } else if (
        highestSelectableTier &&
        parseInt(currentSelectedPlanTier ?? "3") >
          parseInt(highestSelectableTier)
      ) {
        setSelectedPlan(parentRatePlanChargeId ?? "");
      } else {
        triggerPreviewPrimary();
      }
    } else if (setDependentToPrimarySelection && futurePlanChargeId) {
      triggerPreviewPrimary();
    }
  }, [setDependentToPrimarySelection]);

  useEffect(() => {
    if (shouldRevertToDefault) {
      setSelectedPlan(currentRatePlanChargeId ?? "");
    }
  }, [shouldRevertToDefault]);

  return (
    <Row
      key={dependent.email}
      className={
        dependent.eligibilities[0]?.isPendingTermination ? "grayed-out" : ""
      }
    >
      <Col className="manage-plans__member-name" xs={12} lg={5}>
        <h6 className="text-uppercase">Family Member</h6>
        <div>
          <b>
            {dependent.firstName} {dependent.lastName}
          </b>
        </div>
        <span className="mt-minus-half-rem" style={{ display: "block" }}>
          <small>{dependent.email}</small>
        </span>
        {clientConfig.isFamilyMembershipAllowed && (
          <span className="mt-minus-zero2-rem" style={{ display: "block" }}>
            <a
              className={`manage-plans__card__edit-email ${
                isChange ? "disabled-links" : ""
              }`}
              onClick={() => {
                setSelectedMember(dependent);
              }}
              aria-disabled={isChange}
            >
              <FontAwesomeIcon icon={faEnvelopeIcon} /> Edit this member's email
            </a>
          </span>
        )}
      </Col>
      <Col xs={12} lg={7}>
        <h6>FITNESS PACKAGE</h6>
        <h6 style={{ fontWeight: "bold", fontSize: "1.1rem" }}>
          {Object.keys(skuMap).map((key) => {
            if (key === selectedPlan) {
              const name = skuMap[key].name;
              const className =
                skuMap[key].className !== "digital" ? " + Digital Content" : "";
              return name + className;
            }
          })}
        </h6>
        {dependent.eligibilities[0]?.isPendingTermination ? (
          <div style={{ marginBottom: "5px" }}>
            <b>Plan cancellation in progress</b> <br />
          </div>
        ) : null}
        {clientConfig.isDependentAccessLinked ? (
          <p className="mt-minus-half-rem" style={{ fontSize: "0.8rem" }}>
            Managed by primary member's package selection
          </p>
        ) : (
          <Form.Group>
            <Form.Control
              className="package-dropdown"
              as="select"
              value={
                futurePlanChargeId ||
                selectedPlan ||
                currentRatePlanChargeId ||
                ""
              }
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                setSelectedPlan(e.target.value);
              }}
              style={{
                appearance: "auto",
                maxWidth: "600px",
              }}
              disabled={
                disable ||
                dependent.eligibilities[0].isPendingTermination === true ||
                dependent.eligibilities[0].futureSubscription !== null ||
                dependent.eligibilities[0].currentSubscriptionStatus ===
                  "ActiveFutureSuspend" ||
                dependent.eligibilities[0].currentSubscriptionStatus ===
                  "Suspended"
              }
            >
              {dependent?.eligibilities[0].currentSubscriptionStatus !==
              "Active" ? (
                <option value={"termedDefault"} key={"termedDefault"} disabled>
                  Please Select a Package
                </option>
              ) : null}
              {isUnderage ? (
                <BundlesDropDownUnderageDependent
                  planId={futurePlanChargeId || currentRatePlanChargeId}
                  isFuturePlan={futurePlanChargeId !== undefined}
                  highestSelectableTier={
                    futurePlanChargeId !== undefined
                      ? "9"
                      : highestSelectableTier || ""
                  }
                  primaryTier={
                    highestSelectableTier !== undefined
                      ? highestSelectableTier
                      : highestSelectableTier || ""
                  }
                  loaded={() => setDropDownLoaded(true)}
                />
              ) : (
                <BundlesDropDownDependent
                  planId={futurePlanChargeId || currentRatePlanChargeId}
                  highestSelectableTier={highestSelectableTier || ""}
                  underAge={isUnderage}
                  primaryTier={highestSelectableTier || "9"}
                  loaded={() => setDropDownLoaded(true)}
                />
              )}
            </Form.Control>
          </Form.Group>
        )}
      </Col>
    </Row>
  );
};

export default DependentCardBody;
