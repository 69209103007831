import React, { Fragment } from "react";
import { Nav } from "react-bootstrap";
import { ITabPaneFields } from "../../@types/generated/contentful";
import FeatureFactory from "../features/FeatureFactory";
import { Entry } from "contentful";
import hiwBarMW from "../../resources/1-2-3-bar-mw.svg";
import hiwBarFYW from "../../resources/1-2-3-bar-fyw.svg";
import hiwBarWalmart from "../../resources/1-2-3-bar-walmart.svg";
import hiwBarHCSC from "../../resources/1-2-3-bar-hcsc.svg";
import hiwBar from "../../resources/1-2-3-bar.svg";
import { useConfig } from "../../configuration/useConfig";
const TabPane = ({
  step,
  current,
  children,
}: {
  step: number;
  current: number;
  children: React.ReactElement;
}) => {
  if (step === current) {
    return <div>{children}</div>;
  }
  return <div></div>;
};

const Tabs = ({
  tabContent,
  onStep,
  step,
}: {
  tabContent: Entry<ITabPaneFields>[];
  onStep: (index: number) => (e: unknown) => void;
  step: number;
}) => {
  const { config } = useConfig();

  return (
    <Fragment>
      <div className="how-it-works-nav-container">
        <h3 className="nav-container-header">
          Select one of the options below to learn about the products offered
          within your plan.
        </h3>
        <Nav variant="tabs" defaultActiveKey="link-0">
          {tabContent.map((t: Entry<ITabPaneFields>, index: number) => (
            <Nav.Item key={`tabs-nav-${index}`}>
              <Nav.Link eventKey={`link-${index}`} onClick={onStep(index)}>
                {t.fields.title}
              </Nav.Link>
            </Nav.Item>
          ))}
        </Nav>
      </div>
      <div>
        {tabContent.map((t: Entry<ITabPaneFields>, i: number) => (
          <TabPane step={i} current={step} key={`tabs-tab-pane-${i}`}>
            <div className="tab-pane">
              <div className="grid">
                <div className="grid__stepper">
                  <img
                    src={
                      config["client"] === "walmart"
                        ? hiwBarWalmart
                        : config["client"] === "mutual-of-omaha"
                        ? hiwBarMW
                        : config["client"] === "bsca" ||
                          config["client"] === "bcbsa"
                        ? hiwBarFYW
                        : config["client"] === "hcsc"
                        ? hiwBarHCSC
                        : hiwBar
                    }
                    alt="hiw-bar"
                  />
                </div>
                {t.fields.featureOne && (
                  <div className="grid__feature">
                    <FeatureFactory
                      type={t.fields.featureOne.fields.type as string}
                      data={t.fields.featureOne}
                      key={`${t.fields.featureOne.fields.type} - ${i}`}
                      linked
                    />
                  </div>
                )}
                {t.fields.featureTwo && (
                  <div className="grid__feature">
                    <FeatureFactory
                      type={t.fields.featureTwo.fields.type as string}
                      data={t.fields.featureTwo}
                      key={`${t.fields.featureTwo.fields.type} - ${i}`}
                      linked
                    />
                  </div>
                )}
                {t.fields.featureThree && (
                  <div className="grid__feature">
                    <FeatureFactory
                      type={t.fields.featureThree.fields.type as string}
                      data={t.fields.featureThree}
                      key={`${t.fields.featureThree.fields.type} - ${i}`}
                      linked
                    />
                  </div>
                )}
              </div>
            </div>
          </TabPane>
        ))}
      </div>
    </Fragment>
  );
};

export default Tabs;
