import { useContext, useEffect, useState } from "react";
import ActivityLogTile from "../../components/account/Tiles/ActivityLogTile";
import StatusModal from "../../components/wizard/components/StatusModal";
import PlanTile from "../../components/account/Tiles/PlanTile";
import NoPlanTile from "../../components/account/Tiles/NoPlanTile";
import LuxuryGymTile from "../../components/account/Tiles/LuxuryGymTile";
import { Card } from "@tivityhealth/base.ui.card";
import { AccountContext } from "../../context/AccountProvider";
import {
  faExternalLinkAlt,
  faPlayCircle,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import burnAlongTile from "../../resources/FamilyDigital.jpg";
import primeOnDemandTile from "../../resources/LesMillsTile.png";
import primeOnDemandLogo from "../../resources/PrimeOnDemandLogo.png";
import { Spinner } from "react-bootstrap";
import { useConfig } from "../../configuration/useConfig";
import axiosInstance, {
  axiosMemberCardInstance,
  axiosBaseInstance,
} from "../../axios.instance";
import { USER_PAYROLL_STATUS, UserContext } from "../../context/UserProvider";
import AgreeTermsModal from "../../components/wizard/components/AgreeTermsModal";
import ProfileToast from "../../utility/ProfileToast";
import PayrolltoNonPayrollBanner from "../../components/account/PayrolltoNonPayrollBanner";
import { momentStartOfDay } from "../../utility/util";
import BurnAlongLogo from "../../resources/BurnAlongLogo.svg";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuthToken } from "../../components/shared/Auth0TokenProvider";
import AccountSuspensionBanner from "../../components/account/AccountSuspensionBanner";

const Overview = () => {
  const {
    acceptedTerms,
    acceptedProgram,
    member,
    payrollStatus,
    setAccountDetails,
    refresh,
  } = useContext(AccountContext);
  const now = momentStartOfDay().format("MM/DD/YYYY");
  const userStatus = member?.eligibilities[0]?.currentSubscriptionStatus;
  const { config } = useConfig();
  const [loadingBurnalong, setLoadingBurnalong] = useState<boolean>(false);
  const { user } = useContext(UserContext);
  const [handleTermsModal, setHandleTermsModal] = useState<boolean>(true);
  const [, setIsTier] = useState(false);
  const [showError, setShowError] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [showPayrollToNonPayrollBanner, setShowPayrollToNonPayrollBanner] =
    useState<boolean>(false);
  const [showSuspensionBanner, setShowSuspensionBanner] =
    useState<boolean>(false);
  const termDate = momentStartOfDay(
    member?.eligibilities[0]?.memberTermDate
  ).format("MM/DD/YYYY");

  const formatedNow = moment(now, "MM/DD/YYYY");
  const formatedTermDate = moment(termDate, "MM/DD/YYYY");
  const token = useAuthToken();

  useEffect(() => {
    if (
      formatedTermDate > formatedNow &&
      member?.eligibilities[0]?.termReason === "RS50"
    ) {
      setShowPayrollToNonPayrollBanner(true);
    } else if (
      member?.eligibilities[0]?.currentSubscriptionStatus ===
        "ActiveFutureSuspend" ||
      member?.eligibilities[0]?.currentSubscriptionStatus === "Suspended"
    ) {
      setShowSuspensionBanner(true);
    } else {
      setShowPayrollToNonPayrollBanner(false);
    }
  }, []);

  const loadButtonSpinner = () => {
    return (
      <Spinner
        style={{ borderWidth: ".1em", width: 15, height: 15 }}
        animation="border"
        as="span"
        size="sm"
      />
    );
  };

  const handleClickBurnalong = () => {
    setLoadingBurnalong(true);
    axiosInstance
      .post(
        "/partner-sso",
        {},
        {
          headers: {
            Accept: "application/json",
          },
        }
      )
      .then(() => {
        setLoadingBurnalong(false);
        axiosInstance;
        window.open(
          `https://${config["idp.issuer"]}/samlp/${config["burnalong.clientId"]}`
        );
      })
      .catch((e) => {
        setShowError(true);
        setOpenModal(true);
        console.log(e.toJSON());
      });
  };

  const handleClickOnDemand = () => {
    window.open(
      "https://classes.tivityhealth.com/on-demand/?client=hcsc&referrer=www.bcbsilforyourhealth.com"
    );
  };

  const handleAgreementTerms = async () => {
    setHandleTermsModal(false);
    await axiosInstance
      .post(
        "/account/terms",
        {
          acceptedTerms: true,
          acceptedProgram:
            payrollStatus === USER_PAYROLL_STATUS.NonPayroll ||
            payrollStatus === USER_PAYROLL_STATUS.NotApplicable,
        },
        {}
      )
      .then((status) => {
        if (status.status === 200) {
          refresh().then(() => {
            ProfileToast(
              `Your account was successfully updated`,
              true,
              "success"
            );
          });
        }
      })
      .catch((e) => {
        ProfileToast(`Terms and Conditions Accepted Failed`, false, "error");
        console.log(e);
      });
  };

  useEffect(() => {
    if (member?.eligibilities[0]?.productCode === "261") {
      axiosMemberCardInstance
        .get(
          `/Card/image/${member?.eligibilities[0]?.costCenterCode}/${
            member?.eligibilities[0]?.productCode
          }/${member?.firstName + "%20" + member?.lastName}/${
            member?.cardNumber
          }`
        )
        .then(({ data }) => {
          if (data) {
            setAccountDetails({ memberCardBaseImage: `${data}` });
          }
        })
        .catch((e) => {
          console.log(e.toJSON());
        });
    } else {
      axiosBaseInstance
        .get(`/clients/${config["client"]}/members/${user.personId}/card`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(({ data }) => {
          if (data) {
            setAccountDetails({ memberCardBaseImage: `${data}` });
          }
        })
        .catch((e) => {
          console.log(e.toJSON());
        });
    }
  }, []);

  useEffect(() => {
    setIsTier(true);
  }, [member]);

  const handleCancelError = () => {
    setShowError(false);
  };

  return (
    <>
      {payrollStatus === USER_PAYROLL_STATUS.Payroll || user.isFWEnrolled ? (
        acceptedTerms === false ? (
          <AgreeTermsModal
            open={handleTermsModal}
            onAgree={handleAgreementTerms}
            hideProgram={true}
          />
        ) : null
      ) : acceptedTerms === false || acceptedProgram === false ? (
        <AgreeTermsModal
          open={handleTermsModal}
          onAgree={handleAgreementTerms}
        />
      ) : null}
      <div className="widget d-flex flex-wrap flex-row mb-3">
        {showPayrollToNonPayrollBanner && (
          <PayrolltoNonPayrollBanner
            termDate={member?.eligibilities[0]?.memberTermDate}
          />
        )}
        {showSuspensionBanner && <AccountSuspensionBanner />}

        {member?.eligibilities[0]?.productCode === "226" &&
          member?.eligibilities[0]?.currentSubscriptionStatus !== "Cancelled" &&
          member?.eligibilities[0]?.currentSubscriptionStatus !==
            "Suspended" && ( //NEED LOGIC TO CONFIRM IT IS NOT ONLY 260
            <div className="burnalong-card">
              <Card
                iconName={faVideo}
                title="PREMIUM DIGITAL CONTENT"
                imageUrl={burnAlongTile}
              >
                <div>
                  <img
                    src={BurnAlongLogo}
                    width={"135px"}
                    className="burnlong-logo"
                  ></img>
                  <p className="burn-along-paragraph">
                    Enjoy full access to thousands of workouts and live classes
                    including cardio, bootcamps, barre, nutrition, yoga, and
                    even stress management and insomnia support. There's only
                    one thing left to do... feel the burn!
                  </p>
                  <button
                    className="burnalong-button"
                    onClick={handleClickBurnalong}
                  >
                    {loadingBurnalong
                      ? loadButtonSpinner()
                      : "Explore Burnalong"}
                    <FontAwesomeIcon
                      icon={faExternalLinkAlt}
                      className="widget__link-icon"
                    />
                  </button>
                </div>
              </Card>
            </div>
          )}
        {member?.eligibilities[0]?.productCode === "226" &&
          member?.eligibilities[0]?.currentSubscriptionStatus !== "Cancelled" &&
          member?.eligibilities[0]?.currentSubscriptionStatus !== "Suspended" &&
          config["primeOnDemand.enable"] == "true" && (
            <div className="burnalong-card">
              <Card
                iconName={faPlayCircle}
                title="ON DEMAND VIDEOS"
                imageUrl={primeOnDemandTile}
              >
                <div>
                  <img
                    src={primeOnDemandLogo}
                    width={"104px"}
                    className="burnlong-logo"
                  ></img>
                  <p className="burn-along-paragraph">
                    Enjoy 24/7 access to On-Demand videos. There are options for
                    all levels, from beginner workouts, to yoga, to advanced
                    strength training.
                  </p>
                  <button
                    className="burnalong-button"
                    onClick={handleClickOnDemand}
                  >
                    Explore On Demand Videos
                    <FontAwesomeIcon
                      icon={faExternalLinkAlt}
                      className="widget__link-icon"
                    />
                  </button>
                </div>
              </Card>
            </div>
          )}

        {userStatus === undefined || userStatus === "Cancelled" ? (
          <NoPlanTile />
        ) : (
          <PlanTile />
        )}
        {Number(member?.eligibilities[0]?.currentSubscription?.tier) >= 4 && (
          <LuxuryGymTile />
        )}
        {<ActivityLogTile />}
      </div>
      {openModal && (
        <StatusModal
          show={showError}
          status={"GENERIC-ERROR"}
          message=""
          onCancel={handleCancelError}
          onNext={() => {
            return;
          }}
        />
      )}
    </>
  );
};

export default Overview;
