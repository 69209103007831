import {
  faCheckCircle,
  faTags,
  faPiggyBank,
  faCalendarAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Card, Spinner } from "react-bootstrap";
import { Product } from "../../../api/enrollment/Queries";

const PackageCardMOO = ({
  productInfo,
  name,
  price,
  handleChange,
  updateTier,
  selected,
  loading,
  tagline,
  onlyDescription = false,
  showSelectButton = true,
}: {
  productInfo?: Product;
  name: string;
  price: number;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  updateTier?: (Product: Product) => void;
  selected: string;
  loading: boolean;
  tagline?: string;
  onlyDescription?: boolean;
  showSelectButton?: boolean;
}) => {
  return (
    <>
      {loading ? (
        <div className="center-loading">
          <Spinner animation="border" />
        </div>
      ) : (
        <>
          {productInfo && (
            <label
              htmlFor={name}
              className={`package-card-label mutually-well${
                onlyDescription ? " mutually-well-description-only" : ""
              }`}
            >
              <Card className="card">
                <Card.Body className="body">
                  <Card.Title>{name}</Card.Title>
                  <Card.Text>
                    <div className="mb-0 p0 price">${price.toFixed(2)}</div>
                    <div className="p-0 per-month">/ month</div>
                  </Card.Text>
                  {tagline && <Card.Subtitle>{tagline}</Card.Subtitle>}
                </Card.Body>
                <Card.Footer>
                  <div
                    className="zuora-pricing-package"
                    dangerouslySetInnerHTML={{
                      __html: productInfo.description,
                    }}
                  />
                  <div className="zuora-icons">
                    <div className="icon">
                      <FontAwesomeIcon icon={faTags} />
                    </div>
                    <div className="icon">
                      <FontAwesomeIcon icon={faPiggyBank} />
                    </div>
                    <div className="icon">
                      <FontAwesomeIcon icon={faCalendarAlt} />
                    </div>
                  </div>
                </Card.Footer>
                {showSelectButton && (
                  <div className="check-button">
                    <label
                      className="btn btn-default"
                      style={
                        selected === name
                          ? { borderColor: "#21445C" }
                          : {
                              backgroundColor: "#FFFFFF",
                              color: "#21445C",
                              borderColor: "#21445C",
                            }
                      }
                    >
                      <input
                        type="radio"
                        id={name}
                        value={name}
                        name="package.sku"
                        checked={selected === name}
                        onChange={(e) => {
                          handleChange(e);
                          updateTier ? updateTier(productInfo) : null;
                        }}
                      />
                      {selected === name ? (
                        <span>
                          {" "}
                          <FontAwesomeIcon icon={faCheckCircle} /> Selected
                        </span>
                      ) : (
                        <span>Select</span>
                      )}
                    </label>
                  </div>
                )}
              </Card>
            </label>
          )}
        </>
      )}
    </>
  );
};

export default PackageCardMOO;
